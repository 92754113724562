import Choices from "choices.js";
import tippy from "tippy.js";
import { createModal, initializeModalForm } from "./modals";
import { addAlert } from "./alerts";
import { debounce, truncate } from "./utils";

const toolTipsCache = {};
const choicesObjects = {};

export function initializeChoices(container) {
  container
    .querySelectorAll(".choicesjs, .choicesjsmultiple")
    .forEach((selectInput) => {
      let noResultsLabel = gettext("No results found");
      if (selectInput.dataset.createUrl) {
        if (selectInput.dataset.createLabel) {
          noResultsLabel = selectInput.dataset.createLabel;
        } else {
          noResultsLabel = gettext("Create new object");
        }
        noResultsLabel = `
            <button type="button" class="btn btn-green create-modal" data-id="${selectInput.id}">
                ${noResultsLabel}
            </button>`;
      }

      const fetchUrl = selectInput.dataset.fetchUrl;
      let searchEnabled = true;
      if (selectInput.dataset.searchenabled === "false") searchEnabled = false;
      const position = selectInput.dataset.position || "auto";
      const searchFloor = selectInput.dataset.searchFloor
        ? Number(selectInput.dataset.searchFloor)
        : 1;
      const searchPlaceholderValue =
        selectInput.dataset.searchPlaceholderValue || null;

      const choicesObject = new Choices(selectInput, {
        noResultsText: noResultsLabel,
        noChoicesText: noResultsLabel,
        shouldSort: false,
        allowHTML: true,
        searchEnabled: searchEnabled,
        searchChoices: fetchUrl ? false : true,
        removeItemButton: selectInput.hasAttribute("multiple"),
        position: position,
        itemSelectText: gettext("Press to select"),
        searchFloor: searchFloor,
        searchPlaceholderValue: searchPlaceholderValue,
      });
      choicesObjects[selectInput.id] = choicesObject;

      wrapSelectedItem(selectInput);
      addTooltipIcon(selectInput);
      addEditButton(selectInput);

      selectInput.addEventListener("hideDropdown", function (e) {
        choicesObject.clearInput();
      });

      if (fetchUrl) {
        const processSearch = debounce((query) =>
          fetchChoices(choicesObject, query),
        );

        selectInput.addEventListener("showDropdown", function () {
          choicesObject.clearChoices();
          choicesObject.setChoices([
            {
              value: "loading",
              label: gettext("Loading..."),
              disabled: true,
            },
          ]);
          processSearch("");
        });

        selectInput.addEventListener("search", function (e) {
          processSearch(e.detail.value);
        });

        choicesObject.input.element.addEventListener("keyup", (event) => {
          if (
            (event.which === 8 || event.which === 46) &&
            event.target.value === ""
          ) {
            processSearch("");
          }
        });
      }

      if (selectInput.dataset.disabled === "disabled") {
        choicesObject.disable();
      }

      if (selectInput.dataset.parent) {
        const parentInput = document.querySelector(
          `#id_${selectInput.dataset.parent}`,
        );
        function checkParentInput() {
          if (parentInput.value && !parentInput.disabled) {
            choicesObject.enable();
          } else {
            choicesObject.disable();
          }
        }
        checkParentInput();

        parentInput.addEventListener("change", function (e) {
          checkParentInput();
          choicesObject.removeActiveItems();
        });
      }
    });
}

async function fetchChoices(choicesObject, query) {
  const selectInput = choicesObject.passedElement.element;
  const parent = selectInput.dataset.parent;
  let fetchUrl = selectInput.dataset.fetchUrl;
  let params = selectInput.dataset.fetchParams;
  const parentParams = selectInput.dataset.parentParams;
  if (parent) {
    const parentId = `#id_${parent}`;
    fetchUrl = fetchUrl.replace(
      "/0",
      `/${document.querySelector(parentId).value}/`,
    );
    if (parentParams) {
      if (params)
        params += `${parentParams}=${document.querySelector(parentId).value}`;
      else params = `${parentParams}=${document.querySelector(parentId).value}`;
    }
  }
  const response = await fetch(
    `${fetchUrl}?${new URLSearchParams({ q: query.trim() })}&${
      params ? params : ""
    }`,
  );
  if (response.status !== 200) {
    choicesObject.clearChoices();
    return;
  }
  const items = await response.json();
  if (selectInput.dataset.detailUrl) {
    items.forEach((item) => {
      const detailUrl = selectInput.dataset.detailUrl.replace(
        "/0/",
        `/${item.value}/`,
      );
      item.label = `
                ${item.label}
                <button type="button" class="choices__detail" data-url="${detailUrl}">
                    ${gettext("i")}
                </button>`;
    });
  }
  choicesObject.clearChoices();
  if (items.length > 0 && !query && !selectInput.hasAttribute("required")) {
    items.unshift({
      value: "",
      label: "---------",
    });
  }
  await choicesObject.setChoices(items);
}

function addTooltipIcon(element) {
  if (!element.dataset || !element.dataset.detailUrl || !element.value) return;
  const detailUrl = element.dataset.detailUrl.replace(
    "/0/",
    `/${element.value}/`,
  );
  const container = element.parentElement.querySelector(".choices__item");
  container.insertAdjacentHTML(
    "beforeend",
    `<button type="button" class="choices__detail" data-url="${detailUrl}">i</button>`,
  );
}

function wrapSelectedItem(element) {
  const container = element.parentElement.querySelector(".choices__item");
  if (container === null) return;
  container.innerHTML = `<span class="choices__selected__text">${container.childNodes[0].textContent}</span>`;
}

function addEditButton(element) {
  if (!element.dataset.updateUrl || !element.value) return;
  const container = element.parentElement.querySelector(".choices__item");
  if (container.querySelector(".choices__edit") === null) {
    container.insertAdjacentHTML(
      "beforeend",
      `<button type="button" class="choices__edit">Edit</button>`,
    );
  }
}

export function addChoice(element, value, label, selected) {
  choicesObjects[element.id].setChoices([
    { value: value, label: label, selected: selected },
  ]);
  if (selected) wrapSelectedItem(element);
  addTooltipIcon(element);
  addEditButton(element);
}

export function setChoices(element, choices) {
  choicesObjects[element.id].setChoices(choices);
  addTooltipIcon(element);
  addEditButton(element);
}

export async function setChoice(element, value, preload) {
  if (preload) {
    await fetchChoices(choicesObjects[element.id], "");
  }
  choicesObjects[element.id].setChoiceByValue(value);
}

export function getChoicesInstance(element) {
  return choicesObjects[element.id];
}

function handleModalSave(e, selectInput, triggerChange) {
  addChoice(
    document.querySelector(`#${selectInput.id}`),
    e.detail.id,
    e.detail.label,
    true,
  );
  if (triggerChange) selectInput.dispatchEvent(new Event("change"));
  if (e.detail.message) {
    addAlert("success", e.detail.message);
  }
}

let mouseDown = 0;
document.body.onmousedown = function () {
  ++mouseDown;
};
document.body.onmouseup = function () {
  if (mouseDown > 0) --mouseDown;
};
document.addEventListener("keydown", function (e) {
  if (e.key === "Control") ++mouseDown;
});
document.addEventListener("keyup", function (e) {
  if (e.key === "Control") --mouseDown;
});

document.addEventListener("mouseover", function (e) {
  const detailsTarget = e.target.closest(".choices__detail");
  if (!mouseDown)
    if (detailsTarget) {
      const url = detailsTarget.dataset.url;
      const tooltip = tippy(detailsTarget, {
        content: gettext("Loading..."),
        allowHTML: true,
        onShow(instance) {
          if (toolTipsCache[url]) {
            instance.setContent(toolTipsCache[url]);
            return;
          }
          fetch(url)
            .then((response) => response.json())
            .then((data) => {
              const valuesObject = Object.entries(data.values);
              let valuesString = '<div class="fields">';
              valuesObject.forEach((valuePair) => {
                valuesString += `<div class="field"><i>${
                  valuePair[0]
                }</i><b>${truncate(valuePair[1], 100)}</b></div>`;
              });
              valuesString += "</div>";
              toolTipsCache[url] = valuesString;
              instance.setContent(valuesString);
            });
        },
        onHidden(instance) {
          instance.destroy();
        },
      });
      if (!mouseDown) {
        tooltip.show();
      } else {
        tooltip.hide();
      }
    }
});

document.addEventListener(
  "click",
  async function (e) {
    const target = e.target;
    const selectInput = target.closest(".choices")
      ? target.closest(".choices").querySelector("select")
      : null;
    if (target.closest(".create-modal")) {
      let createUrl = selectInput.dataset.createUrl;
      if (selectInput.dataset.parent) {
        createUrl = createUrl.replace(
          "0",
          document.querySelector(`#id_${selectInput.dataset.parent}`).value,
        );
      }

      const response = await fetch(createUrl, {
        headers: {
          ajax: true,
        },
      });
      const createForm = await response.text();
      const modal = createModal(selectInput.dataset.createLabel, createForm);
      initializeModalForm(modal, undefined, dataTimeFieldFormats);

      modal.addEventListener("modal-form-saved", function (e) {
        handleModalSave(e, selectInput, true);
      });
    } else if (target.closest(".choices__edit")) {
      e.stopPropagation();
      const id = target.closest(".choices__item").dataset.value;
      let updateUrl = selectInput.dataset.updateUrl;
      if (selectInput.dataset.parent) {
        const parentId = `#id_${selectInput.dataset.parent}`;
        updateUrl = updateUrl.replace(
          "/0/",
          `/${document.querySelector(parentId).value}/`,
        );
      }
      updateUrl = updateUrl.replace("/0/", `/${id}/`);
      const response = await fetch(updateUrl, {
        headers: {
          ajax: true,
        },
      });
      const updateForm = await response.text();
      const modal = createModal(selectInput.dataset.updateLabel, updateForm);
      initializeModalForm(modal, undefined, dataTimeFieldFormats);

      modal.addEventListener("modal-form-saved", function (e) {
        handleModalSave(e, selectInput, false);
      });
    }
  },
  true,
);

document.querySelectorAll(".choicesjs").forEach((element) => {
  if (!element.dataset.updateUrl) return;
  element.addEventListener("change", function (e) {
    wrapSelectedItem(this);
    addTooltipIcon(this);
    addEditButton(this);
  });
});

window.addEventListener("DOMContentLoaded", async function () {
  initializeChoices(document);
});
export default {
  Choices,
  initializeChoices,
  addChoice,
  setChoice,
  setChoices,
  getChoicesInstance,
};
